import logo from '../images/logo.svg'
import logoMobile from '../images/logo-mobile.svg'
import logoSize from '../images/logo-size-mobile.svg'
import burger from '../images/burger-mobile.svg'
import closeMobile from '../images/close-mobile.svg'
import phone from '../images/phone.svg'
import viber from '../images/viber.svg'
import email from '../images/email.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = '100vw';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Header() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

  const handleDrawerClose = () => {
    setOpen(false);
  };

    return (
      <Box>
        <Box display={{xs: 'block', md: 'none'}}>
          <AppBar open={open}>
            <Toolbar sx={{ py: 2, backgroundColor: 'white' }}>
              <Grid container alignItems="center">
                <Grid xs={3.5} sx={{ textAlign: 'left' }}>
                  <Box className='Start-position'>
                    <a href="/" class="svg">
                    <object className='Header-logomobile' type="image/svg+xml" data={logo}></object>
                    </a>
                  </Box>
                </Grid>
                <Grid xs={5} className='Header-mobile-text'>
                  Беларусь, г. Минск,<br/>ул. Жилуновича, 15, оф. 313
                </Grid>
                <Grid xs={3.5} sx={{ textAlign: 'right' }}>
                  <img onClick={handleDrawerOpen} src={burger} />
                </Grid>
              </Grid>              
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box'
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Divider />
            <Grid container alignItems="center">
              <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
                <img onClick={handleDrawerClose} src={closeMobile} />
              </Box>
              <Grid xs={12} sx={{ textAlign: 'center', p: 4 }}>
                <object className='Header-logo-size-mobile' type="image/svg+xml" data={logoSize}></object>
                <Box sx={{mb:2}}><a onClick={handleDrawerClose} className='Header-menu-mobile' href="#products">Продукция</a></Box>
                <Box sx={{mb:2}}><a onClick={handleDrawerClose} className='Header-menu-mobile' href="#about">О нас</a></Box>
                <Box sx={{mb:2}}><a onClick={handleDrawerClose} className='Header-menu-mobile' href="#why">Почему мы</a></Box>
                <Box sx={{mb:2}}><a onClick={handleDrawerClose} className='Header-menu-mobile' href="#clients">Клиенты</a></Box>
                <Box sx={{mb:2}}><a onClick={handleDrawerClose} className='Header-menu-mobile' href="#contacts">Контакты</a></Box>
              </Grid>
            </Grid>
          </Drawer>
        </Box>
        <Main open={open}>
          <Box sx={{ pt: 5, pb: 5, borderBottom: '1px solid #233C73' }} display={{ xs: 'none', md: 'block' }}>
            <Grid container>
                <Grid xs={3} sx={{ textAlign: 'center' }}>
                      <Box className='Start-position'>
                        <a href="/" class="svg">
                          <object type="image/svg+xml" data={logo}></object>
                        </a>
                      </Box>
                </Grid>
                <Grid xs={5.5} sx={{ textAlign: 'center' }}>
                    <div className='Header-address'>Беларусь, г. Минск, ул. Жилуновича, 15, оф. 313</div>
                    <div className='Header-menu'>
                        <a href="#products">Продукция</a>
                        <a href="#about">О нас</a>
                        <a href="#why">Почему мы</a>
                        <a href="#clients">Клиенты</a>
                        <a href="#contacts">Контакты</a>
                    </div>
                </Grid>
                <Grid xs={3.5}>
                    <div className='Header-contact'>
                        <object type="image/svg+xml" data={phone}></object>
                        <span>+375 17 394 91 99</span>
                    </div>
                    <div className='Header-contact'>
                        <object type="image/svg+xml" data={viber}></object>
                        <span>+375 29 685 86 70</span>
                    </div>
                    <div className='Header-contact'>
                        <object type="image/svg+xml" data={email}></object>
                        <span>profupakovka@gmail.com</span>
                    </div>
                </Grid>
            </Grid>
          </Box>
        </Main>
      </Box>
    )
}

export default Header