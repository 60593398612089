import pribory1 from '../images/promyishlennyje-tovary/pribory1.jpg'
import pribory2 from '../images/promyishlennyje-tovary/pribory2.jpg'
import pribory3 from '../images/promyishlennyje-tovary/pribory3.jpg'
import pribory4 from '../images/promyishlennyje-tovary/pribory4.jpg'
import pribory5 from '../images/promyishlennyje-tovary/pribory5.jpg'
import pribory6 from '../images/promyishlennyje-tovary/pribory6.jpg'
import line1 from '../images/bytovaya-tekhnika/line1.svg'
import line1Custom from '../images/bytovaya-tekhnika/line1-custom.svg'
import line2 from '../images/bytovaya-tekhnika/line2.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { HashLink as Link } from 'react-router-hash-link'

function PromyishlennyjeTovary() {
    return (
        <Box className='Start-position'>
            <Box className='Page-circle-promyishlennyje-tovary'></Box>
            <Container maxWidth="lg">
                <Box sx={{pt: 15, pb: 15}}>
                    <Box className='Page-title'>Упаковка из пенопласта</Box>
                    <Box className='Page-subtitle'>для промышленных товаров</Box>
                </Box>
                <Box className='Page-mobile' display={{ xs: 'block', md: 'none' }}>для электронных приборов</Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position' alignItems='end'>
                    <Box className='Page-line-left-top' display={{ xs: 'none', md: 'block' }}><img className='Page-line-left-top-img' src={line1Custom} /></Box>
                    <Box className='Page-line-left-top' display={{ xs: 'none', md: 'block' }}><img src={line2} /></Box>
                    <Box className='Page-text-left-top' display={{ xs: 'none', md: 'block' }}>для электронных приборов</Box>
                    <Grid item xs={12} sm={3.5}>
                        <img className='full-image' src={pribory1} />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                        <img  className='full-image' src={pribory2} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <img className='full-image' src={pribory3} />
                    </Grid>
                </Grid>
                <Box className='Page-mobile' display={{ xs: 'block', md: 'none' }}>для шлагбаума</Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position'>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line1} /></Box>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line2} /></Box>
                    <Box className='Page-text-right' display={{ xs: 'none', md: 'block' }}>для шлагбаума</Box>
                    <Grid item xs={12} sm={5}>
                        <img className='full-image' src={pribory4} />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                        <img  className='full-image' src={pribory5} />
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                        <img className='full-image' src={pribory6} />
                    </Grid>
                </Grid>
                <Box sx={{textAlign: 'center'}}>
                    <Link to="/#consultation">
                        <Button href="/#consultation" sx={{border: '1px solid #777', color: '#636363', fontFamily: 'Montserrat', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', borderRadius: '0px', px: 5, py: 1.5, mt: 5, mb: 15}} variant="outlined">Узнать стоимость</Button>
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}

export default PromyishlennyjeTovary