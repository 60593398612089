import React, { Component } from "react";
import Slider from "react-slick";

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import arrowLeft from '../arrow-left.svg'
import arrowRight from '../arrow-right.svg'

import slider1 from '../images/trust/slider1.png'
import slider2 from '../images/trust/slider2.png'
import slider3 from '../images/trust/slider3.png'
import slider4 from '../images/trust/slider4.png'
import slider5 from '../images/trust/slider5.png'
import slider6 from '../images/trust/slider6.png'
import slider7 from '../images/trust/slider7.png'
import slider8 from '../images/trust/slider8.png'
import slider9 from '../images/trust/slider9.png'

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      nextArrow: <img src={arrowRight} />,
      prevArrow: <img src={arrowLeft} />
    };
    return (
        <Box sx={{ pt: 15, pb: 7 }}>
            <Container maxWidth="lg" sx={{mb: 15}}>
                <h2 id="clients">Нам доверяют</h2>
            </Container>
            <Container maxWidth="lg">
                <Grid container display={{ xs: 'none', md: 'block' }}>
                    <Grid xs={12}>
                        <Slider {...settings}>
                        <div>
                            <img className="Carousel-img" src={slider1} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider2} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider3} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider4} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider5} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider6} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider7} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider8} />
                        </div>
                        <div>
                            <img className="Carousel-img" src={slider9} />
                        </div>
                        </Slider>
                    </Grid>
                </Grid>
                <Box display={{ xs: 'block', md: 'none' }}>
                    <Grid container spacing={6} justifyContent="center">
                        <Grid item xs={6}>
                            <img className="full-image" src={slider1} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider2} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider3} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider4} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider5} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider6} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider7} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider8} />
                        </Grid>
                        <Grid item xs={6}>
                            <img className="full-image" src={slider9} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
  }
}