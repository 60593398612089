import pallet1 from '../pallet-1.jpg'
import pallet2 from '../pallet-2.jpg'
import pallet3 from '../pallet-3.jpg'
import manufacturing from '../images/manufacturing.svg'
import pencil from '../images/pencil.svg'
import deliveryTime from '../images/delivery-time.svg'
import facebookLike from '../images/facebook-like.svg'
import ecoDrivingIndicator from '../images/eco-driving-indicator.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { createRef, useEffect, useState } from 'react'

function Why() {

    const [data, setData] = useState(null)

    const refComponent = createRef()

    useEffect(() => {
        const height = refComponent.current.getBoundingClientRect().width
        setData(height)
    }, [refComponent])

    const gridStyle = {
        height: data
    };

    return (
        <Box>
            <Container maxWidth="lg">
                <Box sx={{ pt: 15, pb: 7 }}>
                    <h2 style={{ textAlign: 'left' }} id="why">Почему мы?</h2>
                </Box>
            </Container>
            <Grid container alignItems="center" className='Start-position' sx={{mb:8}}>
                <Box className='Why-circle-top'></Box>
                <Grid xs={12} md={1.5}></Grid>
                <Grid xs={12} md={2.5}>
                    <Box className='Why-text'>
                        Опыт в реализации поставок больших партий крупным предприятиям
                    </Box>
                </Grid>
                <Grid style={gridStyle} ref={refComponent} className='Why-grid-one' xs={7} md={2.6}></Grid>
                <Grid xs={.1}></Grid>
                <Grid style={gridStyle} className='Why-grid-two' xs={12} md={2.6} display={{ xs: 'none', md: 'block' }}></Grid>
                <Grid xs={.1}></Grid>
                <Grid style={gridStyle} className='Why-grid-three' xs={12} md={2.6} display={{ xs: 'none', md: 'block' }}></Grid>
            </Grid>

            <Container maxWidth="lg" className='Start-position'>
                <Box className='Why-circle-bottom'></Box>
                <Grid container className='Why-block'>
                    <Grid md={2} xs={3}>
                        <img className='Why-image' src={manufacturing} />
                    </Grid>
                    <Grid md={10} xs={9}>
                        <div className='Why-title'>Собственное производство</div>
                        <p className='Why-description'>Полный цикл производства от момента получения исходного сырья до изготовления конечной продукции.</p>
                    </Grid>
                </Grid>
                <Grid container className='Why-block'>
                    <Grid md={2} xs={3}>
                        <img className='Why-image' src={pencil} />
                    </Grid>
                    <Grid md={10} xs={9}>
                        <div className='Why-title'>Индивидуальный проект</div>
                        <p className='Why-description'>Возможность подготовить комплект конструкторской документации при наличии заказа от 5000 шт и выше.</p>
                    </Grid>
                </Grid>
                <Grid container className='Why-block'>
                    <Grid md={2} xs={3}>
                        <img className='Why-image' src={deliveryTime} />
                    </Grid>
                    <Grid md={10} xs={9}>
                        <div className='Why-title'>Высокая эффективность производства</div>
                        <p className='Why-description'>Благодаря габаритным размерам используемых прессформ. </p>
                    </Grid>
                </Grid>
                <Grid container className='Why-block'>
                    <Grid md={2} xs={3}>
                        <img className='Why-image' src={facebookLike} />
                    </Grid>
                    <Grid md={10} xs={9}>
                        <div className='Why-title'>Качество продукции</div>
                        <p className='Why-description'>Качество поставляемой продукции соответствует требованиям заказчиков.</p>
                    </Grid>
                </Grid>
                <Grid container className='Why-block Why-block-bottom'>
                    <Grid md={2} xs={3}>
                        <img className='Why-image' src={ecoDrivingIndicator} />
                    </Grid>
                    <Grid md={10} xs={9}>
                        <div className='Why-title'>Экологичность</div>
                        <p className='Why-description'>Готовы оказать Вам услугу по последующей переработке различных отходов из пенопласта на собственных площадях. Используем брак продукции в повторном цикле производства.</p>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Why