import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css'
import Header from './components/Header'
import Start from './components/Start'
import Products from './components/Products'
import Advantages from './components/Advantages'
import About from './components/About'
import Why from './components/Why'
import Consultation from './components/Consultation'
import Contacts from './components/Contacts'
import Footer from './components/Footer'
import Carousel from './components/Carousel'

import BytovayaTekhnika from './components/BytovayaTekhnika'
import PromyishlennyjeTovary from './components/PromyishlennyjeTovary'
import Dveri from './components/Dveri'

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div className="App">
          <Header />
          <Start />
          <Products />
          <Advantages />
          <About />
          <Why />
          <Carousel />
          <Consultation />
          <Contacts />
          <Footer />
        </div>
      ),
    },
    {
      path: "/upakovka-dlya-bytovoy-tekhniki",
      element: (
        <div className="App">
          <Header />
          <BytovayaTekhnika />
          <Footer />
        </div>
      ),
    },
    {
      path: "/upakovka-dlya-promyishlennyih-tovarov",
      element: (
        <div className="App">
          <Header />
          <PromyishlennyjeTovary />
          <Footer />
        </div>
      ),
    },
    {
      path: "/upakovka-dlya-dverey",
      element: (
        <div className="App">
          <Header />
          <Dveri />
          <Footer />
        </div>
      ),
    }
  ]);

  createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  );

  return (
    <div className="App">
      <Header />
      <Start />
      <Products />
      <Advantages />
      <About />
      <Why />
      <Consultation />
      <Contacts />
      <Footer />
    </div>
  );
}

export default App;
