import logoWhiteFooter from '../images/logo-white-footer.svg'
import Box from '@mui/material/Box'

function Footer() {
    return (
        <Box className='Footer-background'>
            <div className='Footer-logo'>
                <object className='Footer-img' type="image/svg+xml" data={logoWhiteFooter}></object>
            </div>
            <div className='Footer-copyrights'>ООО «Профессиональная упаковка» (ПрофУпаковка) © 2023. Все права защищены.</div>
        </Box>
    )
}

export default Footer