import bytovayaTekhnika from '../bytovaya-tekhnika.jpg'
import promyshlennyyeTovary from '../promyshlennyye-tovary.jpg'
import dveri from '../dveri.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

function Consultation() {
    return (
        <Box>
            <Container maxWidth="lg">
                <Box sx={{ pt: 15, pb: 7 }}>
                    <h2 id="consultation">Заказать консультацию</h2>
                </Box>
                <Grid container justifyContent="center" className='Consultation-background' padding={{xs: '30px 20px', md: '80px 120px'}}>
                    <div className='Consultation-title'>Для рассчета стоимости заказа<br />заполните форму и мы вам перезвоним!</div>
                    <Grid container>
                        <Grid md={4} xs={12} sx={{p:2}}>
                            <TextField className='Consultation-input' fullWidth id="outlined-basic" label="Контактное лицо" variant="filled" />
                        </Grid>
                        <Grid md={4} xs={12} sx={{p:2}}>
                            <TextField className='Consultation-input' fullWidth id="outlined-basic" label="Номер телефона" variant="filled" />
                        </Grid>
                        <Grid md={4} xs={12} sx={{p:2}}>
                            <TextField className='Consultation-input' fullWidth id="outlined-basic" label="E-mail" variant="filled" />
                        </Grid>
                        <Grid container alignItems="flex-end">
                            <Grid md={8} xs={12} sx={{p:2}}>
                                <TextField multiline rows={4} className='Consultation-input' fullWidth id="outlined-basic" label="Сообщение" variant="filled" />
                            </Grid>
                            <Grid md={4} xs={12} sx={{p:2}}>
                            <Button sx={{backgroundColor: 'white', color: '#BABABA', fontFamily: 'Inter', fontSize: '22px', textTransform: 'none', padding: '10px', borderRadius: 0}} size="large" fullWidth variant="filled">Отправить</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Consultation