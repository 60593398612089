import tsekh from '../tsekh.jpg'
import pallet from '../pallet.jpg'
import zavod from '../zavod.jpg'
import penoplast from '../penoplast.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

function About() {
    return (
        <Box>
            <Box className='About-title'>
                <Container maxWidth="lg">
                    <h2 id="about">О нас</h2>
                </Container>
            </Box>
            <Box className='About-background' sx={{ pt: 3, pb: 3 }}>
                <Container maxWidth="lg">
                    <Grid container alignItems='center' spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid xs={12} sx={{mb: 1}}>
                                    <img className='full-image' src={tsekh} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1.5}>
                                <Grid item xs={4}>
                                    <img className='full-image' src={pallet} />
                                </Grid>
                                <Grid item xs={4}>
                                    <img className='full-image' src={zavod} />
                                </Grid>
                                <Grid item xs={4}>
                                    <img className='full-image' src={penoplast} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className='About-text'>
                                <p>Компания <strong>«Профессиональная упаковка»</strong> производит упаковочные материалы различных видов из пенопласта.</p>
                                <p>Располагая собственными производственными мощностями и обладая успешным опытом реализации ряда крупных проектов в области разработки и массовой поставки отечественным предприятиям упаковочный материал (изделия из пенопласта), мы готовы предложить своим клиентам собственные технические решения в данной области, используя при этом индивидуальный подход к любому заказу, в том числе изготовление мелкосерийное и нестандартное.</p>
                                <p>При этом, мы заботимся об окружающей среде и в случае необходимости готовы оказать Вам услугу по последующей переработке различных отходов из пенопласта на собственных площадях.</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default About