import dveri1 from '../images/dveri/dveri1.jpg'
import dveri2 from '../images/dveri/dveri2.jpg'
import dveri3 from '../images/dveri/dveri3.jpg'
import dveri4 from '../images/dveri/dveri4.jpg'
import dveri5 from '../images/dveri/dveri5.jpg'
import dveri6 from '../images/dveri/dveri6.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { HashLink as Link } from 'react-router-hash-link'

function Dveri() {
    return (
        <Box className='Start-position'>
            <Box className='Page-circle-dveri-top'></Box>
            <Box className='Page-circle-dveri'></Box>
            <Container maxWidth="lg">
                <Box sx={{pt: 15, pb: 15}}>
                    <Box className='Page-title'>Упаковка из пенопласта</Box>
                    <Box className='Page-subtitle'>для дверей</Box>
                </Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position'>
                    <Grid item xs={12} sm={4}>
                        <img className='full-image' src={dveri1} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img  className='full-image' src={dveri2} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className='full-image' src={dveri3} />
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 2}} spacing={4} className='Start-position' alignItems='center'>
                    <Grid item xs={12} sm={4}>
                        <img className='full-image' src={dveri4} />
                        <Box className='Page-img-text'>Транспортная упаковка: пенал повышенной плотности - 400 шт./паллет</Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img  className='full-image' src={dveri5} />
                        <Box className='Page-img-text'>Размеры пенала</Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className='full-image' src={dveri6} />
                        <Box className='Page-img-text'>Пенал серийной плотности - 1000 шт./паллет</Box>
                    </Grid>
                </Grid>
                <Box sx={{textAlign: 'center'}} marginTop={{ xs: 10, md: -7 }}>
                    <Link to="/#consultation">
                        <Button href="/#consultation" sx={{border: '1px solid #777', color: '#636363', fontFamily: 'Montserrat', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', borderRadius: '0px', px: 5, py: 1.5}} variant="outlined">Узнать стоимость</Button>
                    </Link>
                </Box>
                <Box className='Page-text'>
                    Упаковка для двери «Пенал дверной» состоит из профилей П-образной формы, которые легко собираются. Размер стандартный: толщина стенки – 10 мм, длина пенала – 950 мм, ширина в сечении – 40 мм. Упаковка предназначена для полотен шириной 40 мм.
                </Box>
            </Container>
        </Box>
    )
}

export default Dveri