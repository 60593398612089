
import phone from '../images/phone.svg'
import email from '../images/email.svg'
import point from '../images/point.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

function Contacts() {
    return (
        <Box>
            <Container maxWidth="lg">
                <Box sx={{ pt: 15, pb: 7 }}>
                    <h2 style={{ textAlign: 'left' }} id="contacts">Контакты</h2>
                </Box>
                <Grid container sx={{ mb: 15, px: 2 }} className='Start-position'>
                    <Box className='Footer-circle'></Box>
                    <Grid sm={3.5} xs={12} sx={{ px: 1, py: 2 }}>
                        <Box sx={{ mt: 3 }}>
                            <div className='Contacts-title'>Офис</div>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid sm={2} xs={1.3}>
                                    <img src={phone} />
                                </Grid>
                                <Grid sm={10} xs={10.7}>
                                    <div className='Contacts-item-text'>+375 17 394 87 99</div>
                                    <div className='Contacts-item-text'>+375 17 394 91 99 Факс</div>
                                    <div className='Contacts-item-text'>+375 29 685 86 70</div>
                                    <div className='Contacts-item-text-bold'>Отдел отгрузок продукции</div>
                                    <div className='Contacts-item-text'>+375 44 547 87 99 </div>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid sm={2} xs={1.3}>
                                    <img src={email} />
                                </Grid>
                                <Grid sm={10} xs={10.7}>
                                    <div className='Contacts-item-text'>profupakovka@gmail.com</div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid sm={2} xs={1.3}>
                                    <img src={point} />
                                </Grid>
                                <Grid sm={10} xs={10.7}>
                                    <div className='Contacts-item-text'>220026, г. Минск, ул. Жилуновича, 15, офис 313</div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid sm={3.5} xs={12} sx={{ px: 1, py: 2 }}>
                        <Box sx={{ mt: 3 }}>
                        <div className='Contacts-title'>Производство/склад</div>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid sm={2} xs={1.3}>
                                    <img src={phone} />
                                </Grid>
                                <Grid sm={10} xs={10.7}>
                                    <div className='Contacts-item-text'>+375 33 663 95 07</div>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid sm={2} xs={1.3}>
                                    <img src={point} />
                                </Grid>
                                <Grid sm={10} xs={10.7}>
                                    <div className='Contacts-item-text'>Республика Беларусь, Минская область, Смолевичский район, д.Заречье, ул.Зареченская д.36А</div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={12}>
                                    <div className='Contacts-item-text-bold' style={{ marginBottom: '1rem' }}>График работы склада:</div>
                                    <div className='Contacts-item-text'>Пн-птн: 9:00 - 17:00</div>
                                    <div className='Contacts-item-text'>Сб-вскр: выходной</div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid sm={5} xs={12} sx={{ px: 1, py: 2 }}>
                        <Box>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92137.29206307784!2d27.671145938939777!3d53.87383155214479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46da310c9d211759%3A0x10e340d2d28912ba!2z0JfQsNGA0LXRh9GM0LUsIEJlbGFydXM!5e0!3m2!1sen!2spl!4v1462441968957" width="100%" height="400" frameborder="0" style={{ border:0 }} allowfullscreen=""></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Contacts