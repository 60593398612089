import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

function Advantages() {
    return (
        <Box className='Start-position'>
            <Box className='Advantages-circle'></Box>
            <Container maxWidth="lg">
                <Box sx={{ pt: 15, pb: 7 }}>
                    <h2>Преимущества нашей продукции</h2>
                </Box>
                <Box display={{ xs: 'none', md: 'block' }}>
                    <Grid container>
                        <Grid className="Advantages-grid-one" xs={4}>
                            <Box className='Advantages-number'>1</Box>
                            <h3 className='Advantages-title Advantages-margin'>Ударопрочность</h3>
                            <p className='Advantages-description Advantages-margin'>Защита от ударов и<br/>сотрясений при перевозке.</p>
                        </Grid>
                        <Grid className="Advantages-grid-two" xs={4}>
                            <Box className='Advantages-number'>2</Box>
                            <h3 className='Advantages-title Advantages-margin'>Влагозащита</h3>
                            <p className='Advantages-description Advantages-margin'>Продукция не намокает<br/>при контакте с водой.</p>
                        </Grid>
                        <Grid className="Advantages-grid-three" xs={4}>
                            <Box className='Advantages-number'>3</Box>
                            <h3 className='Advantages-title Advantages-margin'>Экологичность</h3>
                            <p className='Advantages-description Advantages-margin'>Полная безопасность для<br/>человека и окружающей среды.</p>
                        </Grid>
                    </Grid>
                </Box>

                <Box display={{ xs: 'block', md: 'none' }} >
                    <Grid container alignItems='center'>
                        <Grid xs={2}>
                            <Box className='Advantages-number-mobile'>1</Box>
                        </Grid>
                        <Grid xs={10}>
                            <h3 className='Advantages-title Advantages-margin'>Ударопрочность</h3>
                            <p className='Advantages-description Advantages-margin'>Защита от ударов и<br/>сотрясений при перевозке.</p>
                        </Grid>
                    </Grid>
                    <Grid container alignItems='center'>
                        <Grid xs={1.5}></Grid>
                        <Grid xs={2}>
                            <Box className='Advantages-number-mobile'>2</Box>
                        </Grid>
                        <Grid xs={8.5}>
                            <h3 className='Advantages-title Advantages-margin'>Влагозащита</h3>
                            <p className='Advantages-description Advantages-margin'>Продукция не намокает<br/>при контакте с водой.</p>
                        </Grid>
                    </Grid>
                    <Grid container alignItems='center'>
                        <Grid xs={2}>
                            <Box className='Advantages-number-mobile'>3</Box>
                        </Grid>
                        <Grid xs={10}>
                            <h3 className='Advantages-title Advantages-margin'>Экологичность</h3>
                            <p className='Advantages-description Advantages-margin'>Полная безопасность для<br/>человека и окружающей среды.</p>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default Advantages