import svch1 from '../images/bytovaya-tekhnika/svch1.jpg'
import svch2 from '../images/bytovaya-tekhnika/svch2.jpg'
import svch3 from '../images/bytovaya-tekhnika/svch3.jpg'
import stiralka1 from '../images/bytovaya-tekhnika/stiralka1.jpg'
import stiralka2 from '../images/bytovaya-tekhnika/stiralka2.jpg'
import holodilnik1 from '../images/bytovaya-tekhnika/holodilnik1.jpg'
import holodilnik2 from '../images/bytovaya-tekhnika/holodilnik2.jpg'
import holodilnik3 from '../images/bytovaya-tekhnika/holodilnik3.jpg'
import line1 from '../images/bytovaya-tekhnika/line1.svg'
import line2 from '../images/bytovaya-tekhnika/line2.svg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { HashLink as Link } from 'react-router-hash-link'

function BytovayaTekhnika() {
    return (
        <Box className='Start-position'>
            <Box className='Page-circle-1'></Box>
            <Box className='Page-circle-2'></Box>
            <Container maxWidth="lg">
                <Box sx={{pt: 15, pb: 15}}>
                    <Box className='Page-title'>Упаковка из пенопласта</Box>
                    <Box className='Page-subtitle'>для бытовой техники</Box>
                </Box>
                <Box className='Page-mobile' display={{ xs: 'block', md: 'none' }}>для СВЧ-печей</Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position'>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line1} /></Box>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line2} /></Box>
                    <Box className='Page-text-right' display={{ xs: 'none', md: 'block' }}>для СВЧ-печей</Box>
                    <Grid item xs={12} sm={4.6}>
                        <img className='full-image' src={svch1} />
                    </Grid>
                    <Grid item xs={12} sm={3.7}>
                        <img  className='full-image' src={svch2} />
                    </Grid>
                    <Grid item xs={12} sm={3.7}>
                        <img className='full-image' src={svch3} />
                    </Grid>
                </Grid>
                <Box className='Page-mobile' display={{ xs: 'block', md: 'none' }}>для стиральных машин</Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position'>
                    <Box className='Page-line-left' display={{ xs: 'none', md: 'block' }}><img src={line1} /></Box>
                    <Box className='Page-line-left' display={{ xs: 'none', md: 'block' }}><img src={line2} /></Box>
                    <Box className='Page-text-left' display={{ xs: 'none', md: 'block' }}>для стиральных машин</Box>
                    <Grid item xs={12} sm={4.6}>
                        <img className='full-image' src={stiralka1} />
                    </Grid>
                    <Grid item xs={12} sm={7.4}>
                        <img  className='full-image' src={stiralka2} />
                    </Grid>
                </Grid>
                <Box className='Page-mobile' display={{ xs: 'block', md: 'none' }}>для холодильников</Box>
                <Grid container sx={{mb: 10}} spacing={4} className='Start-position'>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line1} /></Box>
                    <Box className='Page-line-right' display={{ xs: 'none', md: 'block' }}><img src={line2} /></Box>
                    <Box className='Page-text-right' display={{ xs: 'none', md: 'block' }}>для холодильников</Box>
                    <Grid item xs={12} sm={4.6}>
                        <img className='full-image Page-img' src={holodilnik1} />
                        <img className='full-image' src={holodilnik2} />
                    </Grid>
                    <Grid item xs={12} sm={7.4}>
                        <img  className='full-image' src={holodilnik3} />
                    </Grid>
                </Grid>
                <Box sx={{textAlign: 'center'}}>
                <Link to="/#consultation">
                    <Button sx={{border: '1px solid #777', color: '#636363', fontFamily: 'Montserrat', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', borderRadius: '0px', px: 5, py: 1.5, mt: 5, mb: 15}} variant="outlined">Узнать стоимость</Button>
                </Link>
                </Box>
            </Container>
        </Box>
    )
}

export default BytovayaTekhnika