import bytovayaTekhnika from '../penoplast-fon.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'

function Start() {
    return (
        <Box className='Start-position'>
            <Box className='Start-circle'></Box>
            <Container maxWidth="lg">
                <Grid container alignItems="end" justifyContent="end" className='Start-block'>
                    <Grid xs={12} className='Start-slogan'>
                        <Box className='Start-title-black'>Производим упаковку<br /><span className='Start-title-blue'>из пенопласта</span></Box>
                        <Box className='Start-subtitle'>для бытовой техники и промтоваров</Box>
                    </Grid>
                    <Grid container columnSpacing={6} rowSpacing={2} justifyContent="center" alignItems="end" className='Start-block-bottom'>
                        <Grid item xs={3.3} display={{ xs: 'none', md: 'block' }}>
                            <Box>
                                <Button href="#consultation" sx={{ border: '1px solid #777', color: '#636363', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', borderRadius: '0px', px: 5, py: 1.5 }} variant="outlined">Консультация</Button>
                            </Box>
                        </Grid>
                        <Grid item md={2.8} xs={9} className='Start-position Start-mobile'>
                            <Box className='Start-plus'>+</Box>
                            <Box className='Start-item'>
                                Своё производство
                            </Box>
                        </Grid>
                        <Grid item md={2.8} xs={9} className='Start-position Start-mobile'>
                            <Box className='Start-plus'>+</Box>
                            <Box className='Start-item'>
                                10 лет опыта
                            </Box>
                        </Grid>
                        <Grid item md={2.8} xs={9} sx={{ml: .3}} className='Start-position Start-mobile'>
                            <Box className='Start-plus'>+</Box>
                            <Box className='Start-item-right'>
                                Индивидуальный проект
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Box display={{ xs: 'block', md: 'none' }} sx={{textAlign: 'center', mt: 7}}>
                    <Button href="#consultation" sx={{ border: '1px solid #777', color: '#636363', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', borderRadius: '0px', px: 5, py: 1.5}} variant="outlined">Консультация</Button>
                </Box>
                <Grid container justifyContent="center">
                    <Box className='Start-position Start-button' justifyContent="center">
                        <Box className='Start-parallelogram'></Box>
                        <Box className='Start-parallelogram-text'>Помогаем <i>сохранить промышленную продукцию</i></Box>
                    </Box>
                </Grid>
            </Container>
        </Box>
    )
}

export default Start