import bytovayaTekhnika from '../bytovaya-tekhnika.jpg'
import promyshlennyyeTovary from '../promyshlennyye-tovary.jpg'
import dveri from '../dveri.jpg'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'

function Products() {
    return (
        <Box>
            <Container maxWidth="lg">
                <Box sx={{ pt: 15, pb: 7 }}>
                    <h2 id="products">Наша продукция</h2>
                </Box>
                <Grid container>
                    <Grid sm={4} xs={12}>
                        <div className='Products-title'>Упаковка<br/>для бытовой техники</div>
                        <Box sx={{ pt: 4, pb: 4 }}>
                            <img src={bytovayaTekhnika} className='Products-image full-image' />
                        </Box>
                        <Box className='Products-button'>
                            <Button href="/upakovka-dlya-bytovoy-tekhniki" sx={{ border: '1px solid #777', color: '#636363', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', borderRadius: '0px', px: 5, py: 1.5, mb: 4 }} variant="outlined">Подробнее</Button>
                        </Box>
                    </Grid>
                    <Grid sm={4} xs={12}>
                        <div className='Products-title'>Упаковка для<br/>промышленных товаров</div>
                        <Box sx={{ pt: 4, pb: 4 }}>
                            <img src={promyshlennyyeTovary} className='Products-image full-image' />
                        </Box>
                        <Box className='Products-button'>
                            <Button href="/upakovka-dlya-promyishlennyih-tovarov" sx={{ border: '1px solid #777', color: '#636363', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', borderRadius: '0px', px: 5, py: 1.5, mb: 4}} variant="outlined">Подробнее</Button>
                        </Box>
                    </Grid>
                    <Grid sm={4} xs={12}>
                        <div className='Products-title'>Упаковка<br/>для дверей</div>
                        <Box sx={{ pt: 4, pb: 4 }}>
                            <img src={dveri} className='Products-image full-image' />
                        </Box>
                        <Box className='Products-button'>
                            <Button href="/upakovka-dlya-dverey" sx={{border: '1px solid #777', color: '#636363', fontFamily: 'Inter', fontSize: '15px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', borderRadius: '0px', px: 5, py: 1.5, mb: 4}} variant="outlined">Подробнее</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Products